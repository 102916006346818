import React from "react";
import moment from "moment-timezone";
import {
  Button,
  Popconfirm,
  Icon,
  SectionType,
  FormInputType,
} from "@wellth/web-ui";
import { ReminderInterval, CheckInCategory } from "types/globalTypes";
import { BindTableSectionOptions, AnySectionBinding } from "../types";
import ReminderConfig from "./reminderConfig";
import { isMedicationReminder } from "utils/programSpecific";

const ADD_REMINDER_BUTTON_STYLES: React.CSSProperties = {
  display: "flex",
  flexDirection: "row-reverse",
  margin: "1rem",
};

export interface Reminder {
  checkInCategory: CheckInCategory;
  isDemo: boolean;
  interval: ReminderInterval;
  dueTime: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;
  windowAfterMinutes: number;
  windowBeforeMinutes: number;
  reminderConfigId?: string;
  isRequired: boolean;
}

export type Reminders = Reminder[];

enum ColumnKey {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  CheckInCategory = "checkInCategory",
  Interval = "interval",
  DueTime = "dueTime",
  StartDate = "startDate",
  EndDate = "endDate",
  Delete = "delete",
  IsRequired = "isRequired",
}

const editableTableRows: ColumnKey[] = [
  ColumnKey.CheckInCategory,
  ColumnKey.Interval,
  ColumnKey.DueTime,
  ColumnKey.StartDate,
  ColumnKey.EndDate,
  ColumnKey.IsRequired,
];

const isEditable = (dataIndex: ColumnKey) =>
  editableTableRows.includes(dataIndex);

// eslint-disable-next-line no-underscore-dangle
const isMomentObject = (value: any) => value && value._isAMomentObject;

export interface RemindersInputOptions
  extends BindTableSectionOptions<Reminder> {
  addReminder: () => void;
  deleteReminder: (reminder: Reminder, index: number) => void;
  saveReminder: (reminder: Reminder, index: number) => void;
  programCode: string;
  programTemplate: string;
}

export const Inputs: AnySectionBinding<RemindersInputOptions> = ({
  dataSource,
  deleteReminder,
  addReminder,
  saveReminder,
  programTemplate,
  programCode,
  ...props
}) => ({
  ...props,
  title: "Reminders",
  key: "reminders",
  type: SectionType.Table,
  header: (
    <>
      <ReminderConfig
        programCode={programCode}
        programTemplate={programTemplate}
      />
      <div style={ADD_REMINDER_BUTTON_STYLES}>
        <Button onClick={addReminder}>Add a reminder</Button>
      </div>
    </>
  ),
  data: {
    dataSource,
    rowKey: (record, index) => String(index),
    columns: [
      {
        title: "Type",
        width: "15%",
        key: ColumnKey.CheckInCategory,
        onCell: (record, index: number) => ({
          record,
          editable: isEditable(ColumnKey.CheckInCategory),
          type: FormInputType.Dropdown,
          name: ColumnKey.CheckInCategory,
          onChange: (checkInCategory) =>
            saveReminder(
              {
                ...record,
                checkInCategory,
                isRequired: !isMedicationReminder(record.checkInCateogry)
                  ? true
                  : record.isRequired,
              },
              index,
            ),
          options: [
            { title: "Medication", value: CheckInCategory.Medication },
            { title: "Glucose", value: CheckInCategory.Glucose },
            { title: "Blood Pressure", value: CheckInCategory.BloodPressure },
            { title: "Meal", value: CheckInCategory.Meal },
          ],
        }),
      },
      {
        title: "Frequency",
        width: "15%",
        key: ColumnKey.Interval,
        onCell: (record, index) => ({
          record,
          editable: isEditable(ColumnKey.Interval),
          onChange: (interval) => saveReminder({ ...record, interval }, index),
          type: FormInputType.Dropdown,
          name: ColumnKey.Interval,
          options: [
            { title: "Daily", value: ReminderInterval.Day },
            { title: "Weekly", value: ReminderInterval.Week },
          ],
        }),
      },
      {
        title: "Time",
        key: ColumnKey.DueTime,
        width: "15%",
        onCell: (record, index: number) => ({
          record,
          editable: isEditable(ColumnKey.DueTime),
          onChange: (dueTime) => {
            if (!dueTime || isMomentObject(dueTime)) {
              saveReminder({ ...record, dueTime }, index);
            }
          },
          decoratorOptions: {
            rules: [{ required: true, message: "Reminder time is required" }],
          },
          type: FormInputType.Time,
          name: ColumnKey.DueTime,
        }),
      },
      {
        title: "Start Date",
        key: ColumnKey.StartDate,
        width: "15%",
        onCell: (record, index: number) => ({
          record,
          editable: isEditable(ColumnKey.StartDate),
          onChange: (startDate) => {
            if (!startDate || isMomentObject(startDate)) {
              saveReminder({ ...record, startDate }, index);
            }
          },
          decoratorOptions: {
            rules: [{ required: true, message: "Start Date is required" }],
          },
          type: FormInputType.Date,
          name: ColumnKey.StartDate,
        }),
      },
      {
        title: "End Date",
        key: ColumnKey.EndDate,
        width: "15%",
        onCell: (record, index: number) => ({
          record,
          editable: isEditable(ColumnKey.EndDate),
          onChange: (endDate) => {
            if (!endDate || isMomentObject(endDate)) {
              saveReminder({ ...record, endDate }, index);
            }
          },
          decoratorOptions: {
            rules: [{ required: true, message: "End Date is required" }],
          },
          type: FormInputType.Date,
          name: ColumnKey.EndDate,
        }),
      },
      {
        dataIndex: "operation",
        key: ColumnKey.Delete,
        width: "5%",
        render: (_: any, record: any, index: number) => (
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => deleteReminder(record as Reminder, index)}
          >
            <Icon type="delete" />
          </Popconfirm>
        ),
      },
    ],
  },
});
